
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import NewsTimeline from "@/components/NewsTimeline.vue";
import research, { ResearchArea, ResearchContent } from "@/store/research";
import ResearchAreaCard from "@/components/ResearchAreaCard.vue";

import _ from "lodash";

@Component({
  components: { News: NewsTimeline, ResearchAreaCard },
})
export default class ResearchView extends Vue {
  name = "ResearchView";
  current_tab_index = 0;

  @Watch("current_tab_index") changeRoute(): void {
    let path = "/research";
    if (this.current_tab_index >= 1)
      path += `/${this.tabs[this.current_tab_index].name}`;
    this.$router.replace(path);
  }

  created(): void {
    const areaIndex = this.tabs.findIndex(
      (v) => v.name === this.$route.params.area
    );
    if (areaIndex !== -1) this.current_tab_index = areaIndex;
  }

  get tabs(): ResearchArea[] {
    const result = _.clone(research.areas);
    result.unshift({
      name: "所有方向",
      color: "#6db33f",
      icon: require("../assets/brain.png"),
      home_img: "",
    });
    return result;
  }

  get contents(): ResearchContent[] {
    let result: ResearchContent[] = research.content;
    if (this.current_tab_index >= 1) {
      result = result.filter(
        (v) => v.area === this.tabs[this.current_tab_index].name
      );
    }
    return result;
  }

  get cardHeight(): number {
    if (this.$vuetify.breakpoint.xl) return 450;
    else return 350;
  }
}
