
import Vue from "vue";
import { Component } from "vue-property-decorator";
import news, { News } from "@/store/news";

@Component
export default class NewsTimeline extends Vue {
  name = "NewsTimeline";

  get MAX_NEWS_COUNT(): number {
    if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) return 8;
    return 10;
  }

  get newses(): News[] {
    return this.$i18n.locale === "en" ? news.en : news.zh;
  }

  getMonthStr(input: string): string {
    return input.slice(0, 7); // YYYY-MM共7位
  }
}
