
import Vue from 'vue'
import {Component} from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import research, {ResearchContent} from "@/store/research";

@Component({
  components: {Markdown, S404}
})
export default class ResearchDetail extends Vue {
  name = 'ResearchDetail'

  get research(): ResearchContent | undefined {
    return research.content.find(v => v.name === this.$route.params.name)
  }
}
