
import Vue from "vue";
import { Component } from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import researchArea, {
  ResearchArea as ResearchType,
} from "@/store/researchArea";

@Component({
  components: { Markdown, S404 },
})
export default class ResearchArea extends Vue {
  name = "ResearchArea";

  get researchZH(): ResearchType {
    return researchArea.zh[this.$route.params.area];
  }

  get researchEN(): ResearchType {
    return researchArea.en[this.$route.params.area];
  }

  get research(): ResearchType {
    return this.$i18n.locale === "zh" ? this.researchZH : this.researchEN;
  }
}
